<template>
    <div>
        <!--面包屑-->
        <div class="page-breadcrumb">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>未审核商户</el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <!--内容-->
        <div class="page-content-x">
            <!--搜索-->
            <div v-show="issearch" class="page-content-search">
                <el-form ref="form" :model="search" size="medium" :label-width="this.env.search_label_width">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="商户名称">
                                <el-input v-model="search.company_name"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="联系人姓名">
                                <el-input v-model="search.company_contacts"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="联系方式">
                                <el-input v-model="search.company_contacts_mobile"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="城市">
                                <el-input v-model="search.city"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="商户类型">
                                <el-select v-model="search.type" placeholder="请选择类型" style="width: 100%">
                                    <el-option label="全部" value=""></el-option>
                                    <el-option label="商户" value="1"></el-option>
                                    <el-option label="个人" value="2"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="创建时间">
                                <el-date-picker style="width: 100%" v-model="searchtime" type="daterange"
                                    range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                                    format="yyyy 年 MM 月 dd 日" value-format="yyyy-MM-dd">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="审核类型">
                                <el-select v-model="search.examine_type" placeholder="请选择类型" style="width: 100%">
                                    <el-option label="全部" value="all"></el-option>
                                    <el-option label="新用户注册提交信息" value="1"></el-option>
                                    <el-option label="商户已注册修改信息" value="2"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>

                    </el-row>
                    <el-row class="page-content-search-button">
                        <el-col :span="24">
                            <el-form-item>
                                <el-button icon="el-icon-search" size="medium" type="primary" @click="is_search">搜索
                                </el-button>
                                <el-button icon="el-icon-circle-close" size="medium" @click="init">清空搜索</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </div>
            <!--标题-->
            <el-row>
                <el-col :span="12">
                    <h3>{{ page_name }}</h3>
                </el-col>
                <el-col :span="12">
                    <div class="top-operation-button">
                        <el-button v-if="issearch" icon="el-icon-caret-top" size="medium" type="primary"
                            @click="issearch = !issearch">隐藏搜索
                        </el-button>
                        <el-button v-else icon="el-icon-caret-bottom" size="medium" type="primary"
                            @click="issearch = !issearch">显示搜索
                        </el-button>
                    </div>
                </el-col>
            </el-row>
            <div style="height: 20px;"></div>
            <!--列表-->
            <el-table v-loading="loading" :data="tableData" border size="medium" style="width: 100%">
                <el-table-column prop="company_name" label="商户名称" width="180">
                </el-table-column>
                <el-table-column prop="contact_name" label="负责人姓名" width="120">
                </el-table-column>
                <el-table-column prop="contact_mobile" label="联系方式" width="120">
                </el-table-column>
                <el-table-column prop="contact_mobile" label="企业地址" width="220">
                    <template slot-scope="scope">
                        <p style="margin:0">
                            {{ `${scope.row.province}${scope.row.city}${scope.row.county}${scope.row.address}` }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="uniform_social_credit_code" label="统一社会信用代码/身份证号码" width="180">
                </el-table-column>
                <el-table-column prop="avatar_url" label="品牌LOGO">
                    <template slot-scope="scope">
                        <el-image fit="fit" :size="66" :preview-src-list="[scope.row.logo]" :src="scope.row.logo" />
                    </template>
                </el-table-column>
                <el-table-column prop="business_license" label="营业执照/身份证正反面照片">
                    <template slot-scope="scope">
                        <el-image v-for="(item, index) in scope.row.business_license" :key="index" fit="fit" :size="66"
                            :preview-src-list="scope.row.business_license" :src="item" />
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="类型">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.type === 1">商户</el-tag>
                        <el-tag size="mini" v-if="scope.row.type === 2" type="warning">个人</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="create_time" label="提交时间" width="150">
                </el-table-column>
                <el-table-column prop="update_time" label="审核时间" width="150">
                    <template slot-scope="scope">
                        <p style="margin:0;text-align:center">{{ scope.row.update_time === "1970-01-01 08:00" ? "/" :
                            scope.row.update_time }}</p>
                    </template>
                </el-table-column>
                <el-table-column prop="update_time" label="审核状态">
                    <template slot-scope="scope">
                        <el-tag size="mini" type="warning" v-if="scope.row.status === 0">待审核</el-tag>
                        <el-tag size="mini" type="success" v-if="scope.row.status === 1">已通过</el-tag>
                        <el-tag size="mini" type="danger" v-if="scope.row.status === 2">已拒绝</el-tag>
                    </template>
                </el-table-column>
                <el-table-column fixed="right" label="操作" width="210">
                    <template slot-scope="scope">
                        <el-button v-if="is_auth('company.company.getinfo')" @click="tosee(scope.row)" size="mini">查看
                        </el-button>
                        <el-button v-if="is_auth('company.company.examinenew') && scope.row.status === 0" type="success"
                            @click="examine(1, '', scope.row.id)" size="mini">通过
                        </el-button>
                        <el-button v-if="is_auth('company.company.examinenew') && scope.row.status === 0" type="danger"
                            @click="rejectExamine(scope.row)" size="mini">拒绝
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
            <div style="height: 20px;"></div>
            <!--分页-->
            <el-pagination @current-change="getlist" :page-size="this.env.pageSize" :pager-count="7" background
                layout="prev, pager, next, total" :current-page.sync="page" :total="count">
            </el-pagination>
        </div>
        <el-dialog width="50%" :title="dialogTitle" :visible.sync="isDialogShow" :close-on-press-escape="false"
            :close-on-click-modal="false" @close="clearState">
            <DialogDetail :choose-merchant="chooseMerchant" :is-show-remark="isShowRemark" :is_auth="is_auth"
                @onClose="clearState" @onRemark="dialogRemarkControl" @examine="examine" @smsset="smsset" />
        </el-dialog>
    </div>
</template>
<script>
import DialogDetail from "./DialogDetail";
export default {
    components: { DialogDetail },
    data() {
        return {
            page_name: '未审核商户',
            issearch: false,
            loading: true,      // 加载状态
            search: {},// 搜索内容
            count: 0,        // 数据总条数
            tableData: [],// 列表内容
            page: 1,            // 当前页数
            searchtime: null,   // 时间条件
            isDialogShow: false,
            isShowRemark: false,
            chooseMerchant: {}, // 当前选中的商户信息
            dialogTitle: "查看商户认证信息详情",
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            this.search = {
                company_name: '',
                company_contacts: '',
                company_contacts_mobile: '',
                city: '',
                type: '',
                examine_type: 'all',
                time_type: 'create_time',
            }
            this.searchtime = null
            this.getlist();
        },
        //搜索
        is_search() {
            this.page = 1
            this.getlist();
        },
        // 获取列表
        getlist() {
            let postdata = {
                api_name: "company.company.getlistexaminenew",
                token: this.Tool.get_l_cache('token'),
                pagesize: this.env.pageSize,
                page: this.page,
            }
            // 非必须参数（搜索条件）
            Object.assign(postdata, this.search);
            // 判断时间条件
            if (this.searchtime !== null) {
                postdata.starttime = this.searchtime[0]
                postdata.endtime = this.searchtime[1]
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.tableData = json.data.list
                    this.count = json.data.count
                } else {
                    this.tableData = []
                    this.count = 0
                    this.Tool.errormes(json)
                }
            })
        },
        // 查看
        tosee(item) {
            this.chooseMerchant = item;
            this.isDialogShow = true;
        },
        clearState() {
            this.isDialogShow = false;
            this.isShowRemark = false;
            this.chooseMerchant = null;
        },
        dialogRemarkControl(type) {
            if (this.rejectModelType === 'list') {
                this.isDialogShow = false;
                this.rejectModelType = "";
            }
            this.dialogTitle = type === 'show' ? "拒绝原因" : "查看商户认证信息详情";
            this.isShowRemark = !this.isShowRemark;
        },
        rejectExamine(item) {
            this.chooseMerchant = item;
            this.isDialogShow = true;
            this.isShowRemark = true;
            this.rejectModelType = "list";
            this.dialogTitle = "拒绝原因";
        },
        examine(type, remark, id) {
            let postdata = {
                api_name: "company.company.examinenew",
                token: this.Tool.get_l_cache('token'),
                id: id,
                examine_state: type,
            }
            if (type === 2) {
                postdata["review_remark"] = remark;
            }
            console.log(postdata);
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                        onClose: () => {
                            this.init(); // 获取数据
                            this.clearState();
                        }
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        },
        //设置短信提醒
        smsset(val) {
            let postdata = {
                api_name: "company.company.smssetnew",
                token: this.Tool.get_l_cache('token'),
                company_uuid: this.chooseMerchant.company_uuid,
                issms: val,
            }
            this.loading = true
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                if (json.code === 0) {
                    this.chooseMerchant.issms = val
                    this.$message({
                        message: '操作成功', type: 'success',
                        duration: this.env.message_duration,
                    });
                } else {
                    this.$message.error(json.message);
                }
            })
        },

    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
